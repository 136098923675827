import {createPostRequest} from "./helpers";

const sessionKey = 'lindbakGTM';
const checkDataLayer = () => {
    return !!window.dataLayer;
}
export function getLindbakGTMData() {
    let lindbakGTMData = sessionStorage.getItem(sessionKey);
    if( lindbakGTMData === null ) {
        lindbakGTMData = {
            beginCheckout: false,
            purchase: false,
            setPaymentInfo: false,
            setShippingInfo: false

        };
        sessionStorage.setItem(sessionKey, JSON.stringify(lindbakGTMData));
    } else {
        lindbakGTMData = JSON.parse(lindbakGTMData);
    }
    return lindbakGTMData;
}

export function setLindbakGTMData(key, value = {}) {
    const keys = ['beginCheckout', 'purchase', 'setPaymentInfo', 'setShippingInfo'];
    if( !keys.includes(key) ) {
        throw `Provided key (${key}) is invalid`;
    }
    let lindbakGTMData = getLindbakGTMData();
    lindbakGTMData[key] = value;
    sessionStorage.setItem(sessionKey, JSON.stringify(lindbakGTMData));
}

export function clearLindbakGTMData() {
    sessionStorage.removeItem(sessionKey);
}
export async function GTMViewItem(purchasableId = null) {
    if( checkDataLayer() ) {
        const serverResponse = await createPostRequest('/lindbak-analytics/get-product-details', {
            purchasableId: purchasableId
        });
        if( serverResponse.response.success ) {
            const response = serverResponse.response;
            const eventData = {
                'event': 'view_item',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'value': response.productDetails.productPrice,
                    'items': {
                        'item_name': response.productDetails.productName,
                        'item_id': response.productDetails.productSku,
                        'item_brand': response.productDetails.productBrand,
                        'item_category': response.productDetails.productCategory
                    }
                }
            };
            window.dataLayer.push(eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMViewItemList(listId = '', listName = '', arrayOfPurchasableIds = []) {
    if( checkDataLayer() ) {
        const serverResponse = await createPostRequest('/lindbak-analytics/get-product-details', {
            purchasableIds: arrayOfPurchasableIds
        });
        if( serverResponse.response.success ) {
            const response = serverResponse.response;
            const items = response.productDetails.map(productData => {
                return  {
                    'item_name': productData.productName,
                    'item_id': productData.productSku,
                    'item_brand': productData.productBrand,
                    'item_category': productData.productCategory
                }
            });
            const eventData = {
                'event': 'view_item_list',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'item_list_id': listId, // related_products, productCategoryId, etc..
                    'item_list_name': listName, // Related products, Category name, etc..
                    'items': items
                }
            };
            window.dataLayer.push(eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMRemoveItemFromCart(purchasableId = null, qty = 1) {
    if( checkDataLayer() ) {
        const serverResponse = await createPostRequest('/lindbak-analytics/get-product-details', {
            purchasableId: purchasableId
        });
        if( serverResponse.response.success ) {
            const response = serverResponse.response;
            const eventData = {
                'event': 'remove_from_cart',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'value': response.productDetails.productPrice * qty,
                    'items': {
                        'item_name': response.productDetails.productName,
                        'item_id': response.productDetails.productSku,
                        'item_brand': response.productDetails.productBrand,
                        'item_category': response.productDetails.productCategory,
                        'quantity': qty,
                        'price': response.productDetails.productPrice
                    }
                }
            };
            window.dataLayer.push(eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMAddItemToCart(purchasableId = null, qty = 1) {
    if( checkDataLayer() ) {
        const serverResponse = await createPostRequest('/lindbak-analytics/get-product-details', {
            purchasableId: purchasableId
        });
        if( serverResponse.response.success ) {
            const response = serverResponse.response;
            const eventData = {
                'event': 'add_to_cart',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'value': response.productDetails.productPrice * qty,
                    'items': {
                        'item_name': response.productDetails.productName,
                        'item_id': response.productDetails.productSku,
                        'item_brand': response.productDetails.productBrand,
                        'item_category': response.productDetails.productCategory,
                        'quantity': qty,
                        'price': response.productDetails.productPrice
                    }
                }
            };
            window.dataLayer.push(eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMPurchase(orderId) {
    if( checkDataLayer() ) {
        const gtmData = getLindbakGTMData();
        if( gtmData.purchase ) {
            return;
        }
        const serverResponse = await createPostRequest('/lindbak-analytics/get-order-details', {
            orderId: orderId
        });
        if( serverResponse.response.success ) {
            const response = serverResponse.response;
            
            const items = response.productDetails.map(productData => {
                return {
                    'item_name': productData.productName,
                    'item_id': productData.productSku,
                    'item_brand': productData.productBrand,
                    'item_category': productData.productCategory,
                    'quantity': productData.qty,
                    'price': productData.productPrice * productData.qty,
                }
            });
            
            const eventData = {
                'event': 'purchase',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'transactionId': response.orderDetails.transationId,
                    'value': response.orderDetails.value,
                    'tax': response.orderDetails.tax,
                    'shipping': response.orderDetails.shipping,
                    'items': items
                }
            };
            window.dataLayer.push(eventData);
            setLindbakGTMData('purchase', eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMAddPaymentInfo(PaymentType = 'Credit Card') {
    if( checkDataLayer() ) {
        const gtmData = getLindbakGTMData();
        if( gtmData.setPaymentInfo ) {
            return;
        }
        const serverResponse = await createPostRequest('/lindbak-analytics/get-cart-details', {});
        if( serverResponse.response.success ) {
            const response = serverResponse.response;

            const items = response.productDetails.map(productData => {
                return {
                    'item_name': productData.productName,
                    'item_id': productData.productSku,
                    'item_brand': productData.productBrand,
                    'item_category': productData.productCategory,
                    'quantity': productData.qty,
                    'price': productData.productPrice * productData.qty,
                }
            });

            const eventData = {
                'event': 'add_payment_info',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'payment_type': PaymentType,
                    'value': response.orderDetails.value,
                    'tax': response.orderDetails.tax,
                    'shipping': response.orderDetails.shipping,
                    'items': items
                }
            };
            window.dataLayer.push(eventData);
            setLindbakGTMData('setPaymentInfo', eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMAddShippingInfo() {
    if( checkDataLayer() ) {
        const gtmData = getLindbakGTMData();
        if( gtmData.setShippingInfo ) {
            return;
        }
        const serverResponse = await createPostRequest('/lindbak-analytics/get-cart-details', {});
        if( serverResponse.response.success ) {
            const response = serverResponse.response;

            const items = response.productDetails.map(productData => {
                return {
                    'item_name': productData.productName,
                    'item_id': productData.productSku,
                    'item_brand': productData.productBrand,
                    'item_category': productData.productCategory,
                    'quantity': productData.qty,
                    'price': productData.productPrice * productData.qty,
                }
            });

            const eventData = {
                'event': 'add_shipping_info',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'value': response.orderDetails.value,
                    'tax': response.orderDetails.tax,
                    'shipping': response.orderDetails.shipping,
                    'items': items
                }
            };
            window.dataLayer.push(eventData);
            setLindbakGTMData('setShippingInfo', eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}

export async function GTMBeginCheckout() {
    if( checkDataLayer() ) {
        const gtmData = getLindbakGTMData();
        if( gtmData.beginCheckout ) {
            return;
        }
        
        const serverResponse = await createPostRequest('/lindbak-analytics/get-cart-details');
        if( serverResponse.response.success ) {
            const response = serverResponse.response;

            const items = response.productDetails.map(productData => {
                return {
                    'item_name': productData.productName,
                    'item_id': productData.productSku,
                    'item_brand': productData.productBrand,
                    'item_category': productData.productCategory,
                    'quantity': productData.qty,
                    'price': productData.productPrice * productData.qty,
                }
            });

            const eventData = {
                'event': 'begin_checkout',
                'user_group' : response.userGroup,
                'ecommerce' : {
                    'currency': 'NOK',
                    'value': response.orderDetails.value,
                    'items': items
                }
            };
            window.dataLayer.push(eventData);
            setLindbakGTMData('beginCheckout', eventData);
        } else {
            console.error(serverResponse.response.error);
        }
    }
}